<template>
    <div style="margin-bottom: 100px;">
        <v-container fluid style="margin-bottom: 0px !important;">
            <v-row>
                <v-col cols="12">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
            </v-row>       
        </v-container>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
            <v-layout align-center pr-4>
            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
            <v-layout column>
                <div>
                <strong>{{ snackbar.title }}</strong>
                </div>
                <div>{{ snackbar.text }}</div>
            </v-layout>
            </v-layout>
            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
            <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>
        <v-container fluid>
            <v-row>
                <v-col xs="12" sm="9" md="9" class="col-12">
                    <v-card class="pa-8" outlined height="400">
                        <v-card-title class="lighten-5 p-2">
                            <p>* Field are required. Please fill your fields for Generate</p>
                        </v-card-title>
                        <v-container fluid class="pb-12">
                            <v-row>
                                <v-col class="col-6" xs="12" sm="6" md="4">
                                    <h6 class="blue-lcd mb-1 font-12">Office <strong style="color:red;">*</strong><span></span></h6>
                                    <v-autocomplete
                                        outlined
                                        dense
                                        clearable
                                        v-model="office"
                                        :items="offices"
                                        item-value="office_id"
                                        item-text="office"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-6" xs="12" sm="6" md="4">
                                    <h6 class="blue-lcd mb-1 font-12">Machine Type <strong style="color:red;">*</strong><span></span></h6>
                                    <v-autocomplete
                                        outlined
                                        dense
                                        clearable
                                        v-model="machine_type"
                                        :items="machine_types"
                                        item-value="mach_type"
                                        item-text="descr"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        @change="(event) => [getMachID(event), getMaintenanceSection(event)]"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-6" xs="12" sm="6" md="4">
                                    <h6 class="blue-lcd mb-1 font-12">Machine ID <strong style="color:red;">*</strong><span></span></h6>
                                    <v-autocomplete
                                        outlined
                                        dense
                                        clearable
                                        v-model="machine_id"
                                        :items="machine_ids"
                                        item-value="mach_id"
                                        item-text="descr"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :loading="loading_mach_id"
                                        @change="(event) => [getMaintenanceSection(event)]"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-6" xs="12" sm="6" md="4">
                                    <h6 class="blue-lcd mb-1 font-12">Section <strong style="color:red;">*</strong><span></span></h6>
                                    <v-autocomplete
                                        outlined
                                        dense
                                        clearable
                                        v-model="maint_section"
                                        :items="maint_sections"
                                        item-value="section_id"
                                        item-text="descr"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :loading="loading_section"
                                        @change="(event) => [getPartMachId(event)]"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-6" xs="12" sm="6" md="4">
                                    <h6 class="blue-lcd mb-1 font-12">Part of Mach <strong style="color:red;">*</strong><span></span></h6>
                                    <v-autocomplete
                                        outlined
                                        dense
                                        clearable
                                        v-model="part"
                                        :items="parts"
                                        item-value="part_id"
                                        item-text="descr"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :loading="loading_part"
                                        return-object
                                        @change="(event) => [getPeriode(event)]"
                                        :disabled="disabled_form"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-6" xs="12" sm="6" md="4">
                                    <h6 class="blue-lcd mb-1 font-12">Period <strong style="color:red;">*</strong><span></span></h6>
                                    <v-autocomplete
                                        dense
                                        outlined
                                        clearable
                                        v-model="periode"
                                        :items="periodes"
                                        item-value="value"
                                        item-text="text"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :disabled="true"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="12" sm="12" md="3" class="col-12">
                                    <v-btn block class="mt-7 border-12 mr-2" style="padding: 24px;" elevation="2" @click="generateQR()"><v-icon class="mr-1">mdi-barcode-scan</v-icon>Generate QR Code</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
                <v-col xs="12" sm="3" md="3" class="col-12">
                    <v-card outlined height="400">
                        <v-card-title class="lighten-5 p-2">
                            Result
                            <v-spacer></v-spacer>
                            <v-btn
                            small
                            class="py-5 "
                            @click="printTicket()"
                            >
                                <v-icon>mdi-printer</v-icon> Print
                            </v-btn>
                        </v-card-title>
                        <v-card-text >
                            <div id="divPrintQrCode" style="border: 1px solid rgb(139 131 131 / 87%); padding: 15px;">
                                <v-row align="center" justify="center" class="mt-3">
                                    <v-col cols="12">
                                        <div id="qrcode-2" style="width: 100%;height: 200px;text-align: center;display: flex;justify-content: center;align-items: center;"></div>
                                    </v-col>
                                </v-row>
                                <v-row align="center" justify="center" class="mt-5">
                                    <v-col cols="12">
                                        <h6 class="text-center mt-4" style="font-size: 14px; text-align: center;">
                                            Scan kode QR dengan Web Viva
                                        </h6>
                                    </v-col>
                                </v-row>
                            </div>                                    
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import {filter} from "@/backend-api/filter/index"
import {backendApi} from "@/backend-api/backend-api-sr"
import { Money } from 'v-money'

export default {
    components: {
        Money
    },
    data() {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            money: {
				decimal: '.',
				thousands: ',',
				prefix: '',
				suffix: '',
				precision: 0,
				masked: false
			},
            timeout: 7500,
            search:'',
            breadcumbs: [
                {
                    text: 'Surya Maju Sukses',
                    disabled: false,
                    href: '/admin/sms',
                },
                {
                    text: 'Maintenance',
                    disabled: true,
                },
                {
                    text: 'Generate QR',
                    disabled: true,
                }
            ],
            loading: false,
            dialogdetails:false,
            offices: [],
            office_id: '',
            office: '',
            machine_types: [],
            machine_type: '',
            machine_ids: [],
            machine_id: '',
            maint_section: '',
            section_id: '',
            maint_sections: [],
            parts: [],
            part_id: '',
            part: '',
            mst_maintenance: '',
            mst_maintenances: [],
            periodes: [
                {
                    text: 'DAY',
                    value: 'DAY'
                },
                {
                    text: 'SHIFT',
                    value: 'SHIFT'
                },
                {
                    text: 'TIME',
                    value: 'TIME'
                }
            ],
            periode: '',
            modal_request: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            shifts: [
                {
                    text: '1',
                    value: 1
                },
                {
                    text: '2',
                    value: 2
                },
                {
                    text: '3',
                    value: 3
                }
            ],
            shift: '',
            disabled_shift: false,
            disable_sectid: false,
            details: [],
            disabled_form: false,
            headerSet: false,
            headers:[
                // { text: 'Trans Date', value: 'trans_date',width:'200' , align:'left', groupable: false},
                // { text: 'Check List', value: 'descr' , align:'left', groupable: false},
                { text: 'Check Descr', value: 'check_descr' , align:'left', class: 'my-header-style'},
                { text: 'Shift', value: 'shift_id',width:'100' , align:'left', groupable: false},
                { text: 'Seq No', value: 'seq_no',width:'100' , align:'left', groupable: false},
                { text: 'Min', value: 'min',width:'100' , align:'right', groupable: false},
                { text: 'Max', value: 'max',width:'100' , align:'right', groupable: false},
                { text: 'Result', value: 'check_result',width:'250' , align:'left', groupable: false}
            ],
            tr_id: '',
            remark: '',
            number_of_seqs: [],
            seq_no: '',
            dialog: false,
            envExist: false,
            isLoadingMainSection: false,
            alert: false,
            isMobile: false,
            dialog_scan: false,
            result_scan: '',
            qrcode: '',
            loading_mach_id: false,
            loading_section: false,
            loading_part: false,
            disabled_form: false
        }
    },
    computed:{
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getOffice()
        await this.getMachineType()
        this.$store.dispatch('setOverlay', false)
    },
    methods: {
        async getOffice(){
            await axios.get(`${process.env.VUE_APP_URL}/api/v3/sms/maintenance/filter/office`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.office = '3'
                this.offices = res.data.data
            });
        },
        async getMachineType(){
            await axios.get(`${process.env.VUE_APP_URL}/api/v3/sms/maintenance/filter/mach-type`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.machine_types = res.data.data
            });
        },
        async getMachID(event){
            if (event === '' || event === null) {
                this.machine_id = ''
            } else {
                this.loading_mach_id = true
                const respData = await backendApi.fetchCore(`/api/v3/sms/maintenance/filter/mach-id?office_id=${this.office ? this.office : ''}&mach_type=${this.machine_type ? this.machine_type : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.machine_ids = respData.data.data
                    this.loading_mach_id = false
                }
            }
        },
        async getMaintenanceSection(event){
            if (event === '' || event === null) {
                this.machine_id = ''
                this.maint_section = ''
            } else {
                this.loading_section = true
                const respData = await backendApi.fetchCore(`/api/v3/sms/maintenance/filter/maint-section?office_id=${this.office ? this.office : ''}&mach_type=${this.machine_type ? this.machine_type : ''}&mach_id=${this.machine_id ? this.machine_id : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.loading_section = false
                    this.maint_sections = respData.data.data
                }
            }
        },
        async getPartMachId(event){
            if (event === '' || event === null) {
                this.maint_section = ''
                this.part = ''
            } else {
                this.loading_part = true
                const respData = await backendApi.fetchCore(`/api/v3/sms/maintenance/master-part?office_id=${this.office_id ? this.office_id : ""}&section_id=${this.maint_section ? this.maint_section : ""}&active_flag=Y`, null, false, false, false) 
                if (respData.status === 200) {
                    this.loading_part = false
                    this.parts = respData.data.data
                }
            }
        },
        getPeriode(event){
            console.log('getPeriode')
            console.log(event)
            console.log(this.periode)
            if (event === '' || event === null) {
                this.periode = ''
            } else {
                this.periode = event.period.trim() ? event.period : ''
            }
            console.log(this.periode)
        },
        generateQR(){

            this.$store.dispatch('setOverlay', true)

            if (this.office == '' || this.machine_type === '' || this.machine_id === '' || this.maint_section === '' || this.part === '' || this.periode === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please fill your fields for generate',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)

                return false
            }

            let data = {
                office_id: this.office,
                mach_type: this.machine_type.trim(),
                mach_id: this.machine_id.trim(),
                section_id: this.maint_section.trim(),
                part_id: this.part ? this.part.part_id : 0,
                periode: this.periode,
                // check_id: this.mst_maintenance
            }
            console.log(data);
            let stringdata = JSON.stringify(data)
            if (this.qrcode) {
                this.qrcode.clear();
                this.qrcode.makeCode(stringdata);
            } else {
                this.qrcode = new QRCode(document.getElementById("qrcode-2"), {
                                text: stringdata,
                                width: 220,
                                height: 220,
                                correctLevel : QRCode.CorrectLevel.H
                            });
            }


            this.$store.dispatch('setOverlay', false)

        },
        clear(){
            if (this.qrcode) {
                this.qrcode.clear();
            }
        },
        printTicket(){
            const elem = document.getElementById("divPrintQrCode")
            var domClone = elem.cloneNode(true);

            var $PrintQrCode = document.getElementById("PrintQrCode");

            if (!$PrintQrCode) {
                var $PrintQrCode = document.createElement("div");
                $PrintQrCode.id = "PrintQrCode";
                document.body.appendChild($PrintQrCode);
            }

            $PrintQrCode.innerHTML = "";
            $PrintQrCode.appendChild(domClone);
            window.print();
        }
    },
    watch: {
    }    
}
</script>
<style scoped>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .9;
    position: absolute;
    width: 100%;
}
.tr_datatable{
  background-color: #F5F7F8 !important;
}

.v-card__text {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.v-card {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}


</style>